@font-face {
    font-family: 'Indie Flower';
    src: local('Indie Flower'), url('./assets/fonts/IndieFlower-Regular.ttf') format('truetype');
}


$info:  #ff7f0e;

.handwritten {
    font-family: 'Indie Flower', serif;
    font-size: 1.5rem,
}
.handwritten-sm {
    font-family: 'Indie Flower', serif;
    font-size: 1.5rem,
}


.highcharts-title {
    font-family: 'Indie Flower', serif;
    font-size: 1.5rem,
}

@import "~bootstrap/scss/bootstrap";